<template>
  <div class="banner">
    <div class="content">
      <el-carousel :interval="5000" :height="screenheight" arrow="always" @change="change">
        <el-carousel-item v-for="item in banner" :key="item.id">
          <img :src="indexBanner + item.image" style="width:100%;object-fit:contain;min-width: 1200px;" alt="">
        </el-carousel-item>
				<el-carousel-item >
          <img :src="imgurl" style="width:100%;object-fit:contain;min-width: 1200px;" alt="">
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      current: 0,
      screenheight: document.documentElement.clientHeight + 'px',
      // banner: [
      //   {
      //     id: 0,
      //     image: '1.jpg'
      //   },
      //   {
      //     id: 1,
      //     image: '2.jpg'
      //   },
      //   {
      //     id: 2,
      //     image: '3.jpg'
      //   },
      // ]

			imgurl: require('../assets/img/cloudprint/banner.jpg'),
			
    };
  },
  computed: {
    indexBanner() {
      return 'https://oss-www.kymasf.com/img/banner/'
    },
		banner(){
			return [
        {
          id: 0,
          image: this.$t('m.bannerimage1')
        },
        {
          id: 1,
          image: this.$t('m.bannerimage2')
        },
        {
          id: 2,
          image: this.$t('m.bannerimage3')
        },
      ]
		} 
  },
  mounted() {

    this.screenheight = Math.round(document.documentElement.clientWidth * 600 / 1920) + 'px'
  },
  created() {
    window.addEventListener('resize', () => {
      this.screenheight = Math.round(document.documentElement.clientWidth * 600 / 1920) + 'px'
    })
  },
  methods: {
    change(index) {
      this.current = index
    }
  }
};
</script>

<style lang="less" scoped>
.banner {

  // height: 600px;
  ::v-deep .el-carousel__container {
    position: relative;

    // height: 600px;
    .el-carousel__arrow {
      width: 50px;
      height: 50px;
      font-size: 20px;
    }
  }
}

.el-carousel {
  width: 100%
}
</style>